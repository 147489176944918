import React from 'react';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { useDispatch, useSelector } from 'react-redux';
import { updateCenters } from 'src/store/system/actions';
import { setExpired } from 'src/store/auth/actions';
import { RootState } from 'src/store';
import { CenterInterface, KpisInterface, RevenuesInterface, KpiDetailsInterface } from './types';
import User from 'src/services/user';
import { useHistory } from 'react-router-dom';
import * as logger from 'src/services/log';

const getConfig = (access, method = 'GET') => ({
  method,
  headers: { Authorization: 'Bearer ' + access },
});

export const useCenters = () => {
  const [centers, setCenters] = React.useState<Array<CenterInterface>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const retrieveCenters = async () => {
      try {
        const user = await User.getUser();
        if (user !== null) {
          setIsLoading(true);
          const { access_token } = user;
          const res = await fetch('/api/centers', getConfig(access_token));
          setIsLoading(false);
          if (res.status === 200) {
            const data = await res.json();
            if (data.length === 0) {
              // try again
              setTimeout(retrieveCenters, 1000);
            } else {
              dispatch(updateCenters(data)); // add to store
              setCenters(data);
            }
          } else if (res.status === 401) {
            logger.warn("User was not authenticated to GET '/api/centers'");
            User.removeUser();
            dispatch(setExpired());
          } else {
            logger.error(`GET "/api/centers" `);
            history.push('/unexpected-error');
          }
        } else {
          // no user available
          dispatch(setExpired());
        }
      } catch (e) {
        history.push('/unexpected-error');
      }
    };
    retrieveCenters();
  }, []);
  return { centers, isLoading };
};

export const useKpis = () => {
  const [kpis, setKpis] = React.useState<Array<KpisInterface>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { endDate, startDate, centers } = useSelector((state: RootState) => state.system);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const retrieveKpis = async () => {
      try {
        const user = await User.getUser();
        if (user !== null) {
          setIsLoading(true);
          const { access_token } = user;
          const res = await fetch(
            '/api/dashboard/kpis' +
              `?center-oid=${centers.map((center) => center.id).join(',')}` +
              `&start-date=${moment(startDate).format('YYYY-MM-DD')}` +
              `&end-date=${moment(endDate).format('YYYY-MM-DD')}`,
            getConfig(access_token),
          );
          setIsLoading(false);
          if (res.status === 200) {
            const data = await res.json();
            setKpis(data);
          } else if (res.status === 401) {
            logger.warn("User was not authenticated to GET '/api/dashboard/kpis'");
            User.removeUser();
            dispatch(setExpired());
          } else {
            history.push('/unexpected-error');
          }
        } else {
          // no user available
          dispatch(setExpired());
        }
      } catch (e) {
        history.push('/unexpected-error');
      }
    };
    if (centers.length > 0) {
      retrieveKpis();
    }
  }, [endDate, startDate, centers]);

  return { kpis, isLoading };
};

export const useRevenues = () => {
  const [revenues, setRevenues] = React.useState<Array<RevenuesInterface>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { endDate, startDate, centers } = useSelector((state: RootState) => state.system);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const retrieveKpis = async () => {
      try {
        const user = await User.getUser();
        if (user !== null) {
          const { access_token } = user;
          setIsLoading(true);
          const res = await fetch(
            '/api/dashboard/revenues' +
              `?center-oid=${centers.map((center) => center.id).join(',')}` +
              `&start-date=${moment(startDate).format('YYYY-MM-DD')}` +
              `&end-date=${moment(endDate).format('YYYY-MM-DD')}`,
            getConfig(access_token),
          );
          setIsLoading(false);
          if (res.status === 200) {
            const data = await res.json();
            setRevenues(data);
          } else if (res.status === 401) {
            logger.warn("User was not authenticated to GET '/api/dashboard/revenues'");
            User.removeUser();
            dispatch(setExpired());
          } else {
            history.push('/unexpected-error');
          }
        } else {
          // no user available
          dispatch(setExpired());
        }
      } catch (e) {
        history.push('/unexpected-error');
      }
    };
    if (centers.length > 0) {
      retrieveKpis();
    }
  }, [endDate, startDate, centers]);

  return { revenues, isLoading };
};

export const useCenterKpis = () => {
  const [centerKpis, setCenterKpis] = React.useState<Array<KpiDetailsInterface>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { endDate, startDate, centers, activeKpi } = useSelector((state: RootState) => state.system);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const retrieveCenterKpis = async () => {
      try {
        const user = await User.getUser();
        if (user !== null) {
          const { access_token } = user;
          setIsLoading(true);
          const res = await fetch(
            `/api/dashboard/kpi/centers/${activeKpi}` +
              `?center-oid=${centers.map((center) => center.id).join(',')}` +
              `&start-date=${moment(startDate).format('YYYY-MM-DD')}` +
              `&end-date=${moment(endDate).format('YYYY-MM-DD')}`,
            getConfig(access_token),
          );
          setIsLoading(false);
          if (res.status === 200) {
            const data = await res.json();
            setCenterKpis(orderBy(data, ['value'], ['desc']));
          } else if (res.status === 401) {
            logger.warn("User was not authenticated to GET '/api/dashboard/kpi/centers/'");
            User.removeUser();
            dispatch(setExpired());
          } else {
            history.push('/unexpected-error');
          }
        } else {
          // no user available
          dispatch(setExpired());
        }
      } catch (e) {
        history.push('/unexpected-error');
      }
    };
    if (centers.length > 0 && activeKpi) {
      retrieveCenterKpis();
    }
  }, [endDate, startDate, centers, activeKpi]);

  return { centerKpis, isLoading };
};

export const useMovieKpis = () => {
  const [movieKpis, setMovieKpis] = React.useState<Array<KpiDetailsInterface>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { endDate, startDate, centers, activeKpi } = useSelector((state: RootState) => state.system);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const retrieveMovieKpis = async () => {
      if (activeKpi === 'averageConcessionPurchase' || activeKpi === 'totalVoucherRevenue') {
        setMovieKpis([]);
      } else {
        try {
          const user = await User.getUser();
          if (user !== null) {
            const { access_token } = user;
            setIsLoading(true);
            let res;
            if (activeKpi === 'totalConcessionRevenue') {
              res = await fetch(
                '/api/dashboard/kpi/concessions' +
                  `?center-oid=${centers.map((center) => center.id).join(',')}` +
                  `&start-date=${moment(startDate).format('YYYY-MM-DD')}` +
                  `&end-date=${moment(endDate).format('YYYY-MM-DD')}`,
                getConfig(access_token),
              );
            } else {
              res = await fetch(
                `/api/dashboard/kpi/movies/${activeKpi}` +
                  `?center-oid=${centers.map((center) => center.id).join(',')}` +
                  `&start-date=${moment(startDate).format('YYYY-MM-DD')}` +
                  `&end-date=${moment(endDate).format('YYYY-MM-DD')}`,
                getConfig(access_token),
              );
            }
            setIsLoading(false);
            if (res.status === 200) {
              const data = await res.json();
              setMovieKpis(orderBy(data, ['value'], ['desc']));
            } else if (res.status === 401) {
              logger.warn(
                activeKpi === 'totalConcessionRevenue'
                  ? "User was not authenticated to GET '/api/dashboard/kpi/concessions'"
                  : "User was not authenticated to GET '/api/dashboard/kpi/movies/'",
              );
              User.removeUser();
              dispatch(setExpired());
            } else {
              history.push('/unexpected-error');
            }
          } else {
            // no user available
            dispatch(setExpired());
          }
        } catch (e) {
          history.push('/unexpected-error');
        }
      }
    };
    if (centers.length > 0 && activeKpi) {
      // only run function if values are defined
      retrieveMovieKpis();
    }
  }, [endDate, startDate, centers, activeKpi]);

  return { movieKpis, isLoading };
};
