import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
// store
import { RootState } from 'src/store';
import { closeModal } from 'src/store/layout/actions';
// assets
import { Icon, VectorGraphic } from 'src/components/common';
import { Modal } from './components/modal';
import User from 'src/services/user';
import {
  RootContainer,
  StyledBurger,
  StyledBurgerContainer,
  Wrapper,
  Navbar,
  StyledMenu,
  ButtonContainer,
  Spacer,
  MobileNavbar,
  CenterLogo,
  ButtonText,
} from './index.styles';
import { useTranslation } from 'react-i18next';

interface MenuProps {
  children: JSX.Element;
}

export const SideMenu: React.FC<MenuProps> = ({ children }) => {
  // get the current route
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const layout = useSelector((state: RootState) => state.layout);
  const { modalIsVisible, modalContent } = layout;
  // store the menu state
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [pathname, setPathname] = useState<string>(location.pathname);
  const { push } = useHistory();

  useEffect(() => {
    if (location.pathname !== pathname) {
      setTimeout(() => {
        setIsOpen(false);
        setPathname(location.pathname);
      }, 100);
    }
  }, [location.pathname]);

  const onCloseModal = () => dispatch(closeModal());

  return (
    <>
      <RootContainer isblurred={modalIsVisible}>
        <StyledMenu isopen={isOpen}>
          <Navbar isopen={isOpen}>
            <VectorGraphic name="compeso" />
          </Navbar>
          <ButtonContainer to="/" isactive={(pathname === '/').toString()} isopen={isOpen.toString()}>
            <Icon name="home" width="20pt" />
            <ButtonText isopen={isOpen}>{t('common.home')}</ButtonText>
          </ButtonContainer>
          <ButtonContainer to="/dashboard" isactive={(pathname === '/dashboard').toString()} isopen={isOpen.toString()}>
            <Icon name="analytics" width="20pt" />
            <ButtonText isopen={isOpen}>{t('common.dashboard')}</ButtonText>
          </ButtonContainer>
          <Spacer />
          <ButtonContainer to="/" onClick={User.logout} isactive={false.toString()} isopen={isOpen.toString()}>
            <Icon name="exit" width="20pt" />
            <ButtonText isopen={isOpen}>{t('common.logout')}</ButtonText>
          </ButtonContainer>
        </StyledMenu>

        <MobileNavbar>
          <CenterLogo>
            <VectorGraphic name="compeso" width="100pt" />
          </CenterLogo>
          {pathname === '/' ? (
            <Icon name="analytics" width="18pt" onClick={() => push('/dashboard')} />
          ) : (
            <Icon name="home" width="18pt" onClick={() => push('/')} />
          )}
          <Icon name="exit" width="18pt" onClick={User.logout} />
          {/*
          <StyledBurger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
            {[...new Array(3)].map((id) => <div key={id} />)}
          </StyledBurger>
          */}
        </MobileNavbar>

        <StyledBurgerContainer>
          <StyledBurger isopen={isOpen} onClick={() => setIsOpen(!isOpen)}>
            {Array.from(Array(3), (e, i) => i + 1).map((id) => (
              <div key={id} />
            ))}
          </StyledBurger>
        </StyledBurgerContainer>
        <Wrapper>{children}</Wrapper>
      </RootContainer>
      {modalContent !== null && <Modal onClose={onCloseModal}>{modalContent}</Modal>}
    </>
  );
};
