import styled from 'styled-components';
import { Container } from 'src/components/common';
import { Link } from 'react-router-dom';

interface StyleProps {
  isopen?: boolean;
  isactive?: boolean;
  isblurred?: boolean;
}

export const RootContainer = styled.main<StyleProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  filter: ${({ isblurred: isBlurred }) => (isBlurred ? 'blur(8px)' : undefined)};
  transition: filter 0.2s ease-in-out;
  padding-left: var(--sidebar-width-small);
  @media (max-width: 625px) {
    padding-left: 0;
    padding-top: 40pt;
  }
`;

export const StyledBurger = styled.button<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18pt;
  height: 18pt;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 18pt;
    height: 2pt;
    background-color: var(--color-grey-dark);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ isopen: isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
      margin-bottom: 1pt;
      border-radius: 1pt;
    }

    :nth-child(2) {
      opacity: ${({ isopen: isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isopen: isOpen }) => (isOpen ? 'translateX(20px)' : 'translateX(0)')};
      margin-top: 3pt;
      margin-bottom: 3pt;
      border-radius: 1pt;
    }

    :nth-child(3) {
      margin-top: 1pt;
      transform: ${({ isopen: isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
      border-radius: 1pt;
    }
  }
`;

export const StyledBurgerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 101;
  justify-content: center;
  align-items: center;
  height: var(--navbar-height);
  padding-left: calc(var(--margin-xl) + 12pt);
  @media (max-width: 625px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-bg-regular);
`;

export const Navbar = styled.div<StyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--navbar-height);
  width: 100%;
  svg {
    transition: all 0.2s linear;
    opacity: ${({ isopen: isOpen }) => (isOpen ? '1' : '0')};
  }
`;

export const StyledMenu = styled.nav<StyleProps>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background: var(--color-bg-light);
  transition: width 0.3s ease-in-out;
  width: ${({ isopen: isOpen }) => (isOpen ? 'var(--sidebar-width-regular)' : 'var(--sidebar-width-small)')};
  background-color: var(--color-bg-light);
  box-shadow: 8pt 3pt 38pt rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  padding-bottom: var(--margin-m);
  @media (max-width: 625px) {
    width: ${({ isopen: isOpen }) => (isOpen ? 'var(--sidebar-width-regular)' : 0)};
  }
`;

export const ButtonContainer = styled(Link)<StyleProps>`
  display: flex;
  flex-direction: row;
  margin: 0 var(--margin-l);
  background-color: ${({ isActive }) => (isActive ? 'rgba(var(--color-blue-rgb),0.2)' : 'transparent')};
  color: ${({ isActive }) => (isActive ? 'var(--color-blue)' : 'var(--color-grey-regular)')};
  height: 50pt;
  border-radius: var(--border-radius-m);
  padding: 0 var(--margin-m);
  align-items: center;
  text-decoration: none;
  margin-top: var(--margin-xs);
  svg {
    fill: ${({ isActive }) => (isActive ? 'var(--color-blue)' : 'var(--color-grey-regular)')};
  }
  @media (max-width: 625px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
`;

export const Spacer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MobileNavbar = styled(Container)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40pt;
  background-color: var(--color-bg-light);
  z-index: 100;
  @media (max-width: 625px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  box-shadow: 0pt 3pt 20pt rgba(0, 0, 0, 0.05);
  svg {
    fill: var(--color-grey-dark);
  }
`;

export const CenterLogo = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 70px;
  right: 70px;
  height: 40pt;
  justify-content: center;
  align-items: center;
`;

export const ButtonText = styled.p<StyleProps>`
  margin-left: var(--margin-m);
  font-size: var(--font-size-m);
  font-weight: bold;
  transition: all 0.2s linear;
  opacity: ${({ isopen: isOpen }) => (isOpen ? '1' : '0')};
`;
