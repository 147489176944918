import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateDates } from 'src/store/system/actions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--color-bg-regular);
`;

const Title = styled.h1`
  font-size: 4.5rem;
  font-weight: 800;
  color: var(--color-grey-dark);
  text-align: center;
`;

const Error = styled.h3`
  font-size: var(--font-size-h3);
  font-weight: 500;
  color: var(--color-grey-regular);
  text-align: center;
  margin: var(--margin-m) var(--margin-l);
  max-width: 500pt;
`;

export const UnexpectedError = () => {
  let { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    // reset dates
    dispatch(
      updateDates({
        dateShortcutName: t('common.today'),
        startDate: new Date(),
        endDate: new Date(),
      }),
    );
  }, []);

  return (
    <Container>
      <Title>{t('error.unexpectedError')}</Title>
      <Error>{t('error.unexpectedErrorText')}</Error>
    </Container>
  );
};
