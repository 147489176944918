import { SystemState, SystemActionTypes, UPDATE_DATES, UPDATE_CENTERS, UPDATE_KPI } from './types';

const initialState: SystemState = {
  dateShortcutName: '',
  startDate: new Date(),
  endDate: new Date(),
  centers: [],
  activeKpi: '',
};

export function systemReducer(state = initialState, action: SystemActionTypes): SystemState {
  switch (action.type) {
    case UPDATE_DATES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_CENTERS: {
      return {
        ...state,
        centers: action.payload,
      };
    }
    case UPDATE_KPI: {
      return {
        ...state,
        activeKpi: action.payload,
      };
    }
    default:
      return state;
  }
}
