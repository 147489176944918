import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRevenues } from 'src/data/hooks';
import moment from 'moment';
import { RotateSpinner } from 'react-spinners-kit';
import { DEFAULT_REVENUES } from '../../constants';
import {
  Card,
  Container,
  TitleRow,
  UnitInfoText,
  Row,
  ColorIndicator,
  IndicatorText,
  Title,
  ChartContainer,
  StyledChart,
  NoDataPlaceholder,
  SpinnerContainer,
  StyledResetIcon,
  LegendRow,
} from './revenues.style';

interface ChartData {
  label: string;
  data: Array<Array<number | Date>>;
  color: string;
}

interface IUnit {
  name: string;
  value: 1 | 100 | 1000 | 1000000;
}

export const Revenues: React.FC = () => {
  const { t } = useTranslation();
  const { revenues, isLoading } = useRevenues();
  const [data, setData] = React.useState<Array<ChartData>>([]);
  const [min, setMin] = React.useState<any>();
  const [max, setMax] = React.useState<any>();
  const [isEmpty, setIsEmpty] = React.useState(true);

  const getUnit = (): IUnit => {
    if (revenues.length > 0) {
      let _max = 0;
      revenues.forEach(({ totalTicketsRevenue, totalConcessionRevenue, totalVoucherRevenue }) => {
        if (totalTicketsRevenue > _max) _max = totalTicketsRevenue;
        if (totalConcessionRevenue > _max) _max = totalConcessionRevenue;
        if (totalVoucherRevenue > _max) _max = totalVoucherRevenue;
      });
      if (_max > 1000000) {
        return { name: `${t('dashboard.inM')} €`, value: 1000000 };
      }
      if (_max > 10000) {
        return { name: `${t('dashboard.inK')} €`, value: 1000 };
      }
      if (_max > 100) {
        return { name: `${t('dashboard.inH')} €`, value: 100 };
      }
      return { name: '', value: 1 };
    }
    return { name: '', value: 1 };
  };

  React.useEffect(() => {
    if (revenues.length > 0) {
      // remove time from date and covert to new Date()
      const unit = getUnit();
      setData([
        {
          label: t('kpis.totalTicketsRevenue'),
          data: revenues.map(({ date, totalTicketsRevenue }) => [
            moment(moment(date).format('YYYY-MM-DD')).toDate(),
            totalTicketsRevenue / unit.value,
          ]),
          color: 'var(--color-blue)',
        },
        {
          label: t('kpis.totalConcessionRevenue'),
          data: revenues.map(({ date, totalConcessionRevenue }) => [
            moment(moment(date).format('YYYY-MM-DD')).toDate(),
            totalConcessionRevenue / unit.value,
          ]),
          color: 'var(--color-purple)',
        },
        {
          label: t('kpis.totalVoucherRevenue'),
          data: revenues.map(({ date, totalVoucherRevenue }) => [
            moment(moment(date).format('YYYY-MM-DD')).toDate(),
            totalVoucherRevenue / unit.value,
          ]),
          color: 'var(--color-teal)',
        },
      ]);
      setIsEmpty(false);
    } else {
      // set something to be displayed
      setData(DEFAULT_REVENUES);
      setIsEmpty(true);
    }
  }, [revenues]);

  const onResetBoundaries = (): void => {
    setMin(undefined);
    setMax(undefined);
  };

  const axes = React.useMemo(
    () => [
      {
        primary: true,
        position: 'bottom',
        type: 'time',
        hardMin: min,
        hardMax: max,
      },
      {
        type: 'linear',
        position: 'left',
      },
    ],
    [max, min],
  );

  // const isEmpty = React.useMemo(() => revenues.length === 0, [revenues]);

  const series = React.useMemo(() => (revenues.length === 1 ? { type: 'bar' } : { type: 'line' }), [revenues]);

  const onBrush = React.useMemo(
    () => ({
      onSelect: (brushData) => {
        setMin(Math.min(brushData.start, brushData.end));
        setMax(Math.max(brushData.start, brushData.end));
      },
    }),
    [],
  );

  return (
    <Card data-test-id="revenues-chart">
      <TitleRow>
        <Row>
          <Title>{t('common.revenues')}</Title>
          <UnitInfoText>{React.useMemo(getUnit, [revenues]).name}</UnitInfoText>
          {Boolean(min) && <StyledResetIcon onClick={onResetBoundaries} size="20pt" name="reload-circle" />}
        </Row>
        <LegendRow>
          <ColorIndicator color="var(--color-purple)" />
          <IndicatorText>{t('common.concession')}</IndicatorText>

          <ColorIndicator color="var(--color-blue)" />
          <IndicatorText>{t('common.tickets')}</IndicatorText>

          <ColorIndicator color="var(--color-teal)" />
          <IndicatorText>{t('common.vouchers')}</IndicatorText>
        </LegendRow>
      </TitleRow>
      <Container>
        <ChartContainer>
          <StyledChart
            series={series}
            data={data}
            axes={axes}
            brush={onBrush}
            tooltip={isEmpty ? +false : +true}
            isblurry={isEmpty ? +true : +false}
          />
          {isEmpty && !isLoading && <NoDataPlaceholder>{t('common.noData')}</NoDataPlaceholder>}
          {isLoading && (
            <SpinnerContainer>
              <RotateSpinner size={30} color="var(--color-blue)" loading />
            </SpinnerContainer>
          )}
        </ChartContainer>
      </Container>
    </Card>
  );
};
