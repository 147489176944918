import { combineReducers } from 'redux';
import { systemReducer } from './system/reducers';
import { layoutReducer } from './layout/reducers';
import { authReducer } from './auth/reducers';

export const rootReducer = combineReducers({
  system: systemReducer,
  layout: layoutReducer,
  auth: authReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
