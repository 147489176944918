import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// store
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useCenters } from 'src/data/hooks';
import { openModal } from 'src/store/layout/actions';
// components
import { Container, Button, Icon } from 'src/components/common';
import { Dropdown, Kpis, Calendar, Revenues, KpiDetails } from './components';
import { dateDiff } from './helpers/calendar';

const StyledContainer = styled(Container)`
  margin-top: var(--margin-m);
  margin-bottom: var(--margin-m);
`;

const Title = styled.h2`
  font-size: var(--font-size-h2);
  font-weight: bold;
  color: var(--color-grey-dark);
  @media (max-width: 625px) {
    font-size: var(--font-size-h3);
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  @media (max-width: 625px) {
    align-self: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  max-width: 50%;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: var(--color-bg-light);
  }
`;

const ButtonText = styled.p`
  margin-left: var(--margin-s);
`;

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dateShortcutName = useSelector((state: RootState) => state.system.dateShortcutName);
  const startDate = useSelector((state: RootState) => state.system.startDate);
  const endDate = useSelector((state: RootState) => state.system.endDate);
  const { centers } = useCenters();
  const onClickButton = () => dispatch(openModal(<Calendar />));

  const getButtonText = () => {
    if (!dateShortcutName || (dateDiff(startDate, endDate) === 0 && dateDiff(startDate, new Date(Date.now())) === 0)) {
      return t('common.today');
    }

    return dateShortcutName;
  };

  return (
    <StyledContainer>
      <Row>
        <TitleRow>
          <Title>{t('common.dashboard')}</Title>
          <Dropdown items={centers} />
        </TitleRow>
        <StyledButton onClick={onClickButton} data-test-id="calendar-button">
          <ButtonRow>
            <Icon name="calendar" size="14pt" />
            <ButtonText>{getButtonText()}</ButtonText>
          </ButtonRow>
        </StyledButton>
      </Row>
      <Kpis />
      <KpiDetails />
      <Revenues />
    </StyledContainer>
  );
};
