import React from 'react';
import styled from 'styled-components';
import { MovieDetails } from './movie-details';
import { CenterDetails } from './center-details';

const Card = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--margin-l);
  margin-top: var(--margin-l);
  @media (max-width: 1260px) {
    grid-column-gap: var(--margin-r);
  }
  @media (max-width: 1110px) {
    grid-template-columns: 1fr;
    grid-row-gap: var(--margin-r);
    grid-column-gap: var(--margin-r);
  }
`;

export const KpiDetails: React.FC = () => {
  return (
    <Card>
      <CenterDetails />
      <MovieDetails />
    </Card>
  );
};
