import moment from 'moment';
import chunk from 'lodash/chunk';

// TODO change language option
moment.locale('de');

const getDaysInMonth = (month, year) => {
  return moment(`${month}-${year}`, 'MM-YYYY').daysInMonth();
};

const getFirstWeekdayOfMonth = (month, year) => {
  const monday = moment(`${month}-${year}`, 'MM-YYYY').startOf('month').weekday();
  // get cinema day
  if (monday - 3 >= 0) {
    return monday - 3;
  } else {
    return monday + 4;
  }
};

export const getPrevMonthYear = (month, year) => {
  // If it is January... prev month is Dec of the previous year
  if (month === 1) {
    return {
      month: 12,
      year: year - 1,
    };
  }
  // Otherwise, same year, but month - 1
  return {
    month: month - 1,
    year,
  };
};

export const getNextMonthYear = (month, year) => {
  // If it is December... next month is january
  if (month === 12) {
    return {
      month: 1,
      year: year + 1,
    };
  }
  // Otherwise, same year, but month + 1
  return {
    month: month + 1,
    year,
  };
};

export const getDatesInMonthDisplay = (month, year) => {
  const daysInMonth = getDaysInMonth(month, year);
  const firstWeekday = getFirstWeekdayOfMonth(month, year);
  const result = [];

  const prev = getPrevMonthYear(month, year);
  const prevDaysInMonth = getDaysInMonth(prev.month, prev.year);

  // Add prev overflow dates...
  for (let j = firstWeekday - 1; j >= 0; j--) {
    result.push({
      date: moment(`${prev.month}-${prevDaysInMonth - j}-${prev.year}`, 'MM-DD-YYYY').toDate(),
      isCurrentMonth: false,
    });
  }

  // Add current month's dates
  for (let i = 1; i <= daysInMonth; i++) {
    result.push({
      date: moment(`${month}-${i}-${year}`, 'MM-DD-YYYY').toDate(),
      isCurrentMonth: true,
    });
  }

  // Overflow dates for next month to meet 42 days per month display   requirement
  if (result.length < 42) {
    const daysToAdd = 42 - result.length;
    const next = getNextMonthYear(month, year);

    for (let k = 1; k <= daysToAdd; k++) {
      result.push({
        date: moment(`${next.month}-${k}-${next.year}`, 'MM-DD-YYYY').toDate(),
        isCurrentMonth: false,
      });
    }
  }
  return result;
};

export const getDayNames = (array) => {
  const result = [];
  for (let i = 0; i < 7; i++) {
    const date = array[i].date;
    result.push(moment(date).format('ddd'));
  }
  return result;
};

export const getDayDiff = (date0, date1) => {
  /* return date 0 - date 1 in number of day => later dates - earlier date > 0 */
  const startDate = Date.UTC(date0.getFullYear(), date0.getMonth(), date0.getDate());
  const endDate = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  var ms = startDate - endDate;
  return Math.floor(ms / 1000 / 60 / 60 / 24);
};

export const dateDiff = (date1, date2) => {
  return (date1.getDate() - date2.getDate()) / (24 * 60 * 60 * 1000);
};

export const isInFutureMonths = (date, year, month) => {
  // evaluate if a date is in future months
  const date0 = new Date(Date.UTC(year, month - 1));
  const date1 = new Date(Date.UTC(date.getFullYear(), date.getMonth()));
  if (date0 === date1) return false;
  else return date1 > date0;
};

export const isInPastMonths = (date, year, month) => {
  // evaluate if a date is in past months
  const date0 = new Date(Date.UTC(year, month - 1));
  const date1 = new Date(Date.UTC(date.getFullYear(), date.getMonth()));
  if (date0 === date1) return false;
  else return date1 < date0;
};

export const getShortcuts = (t) => {
  // get today
  const now = new Date();
  // get this month days
  const month = getDatesInMonthDisplay(now.getMonth() + 1, now.getFullYear());
  // get month returns 0 -> 11
  // find position of today in this month
  const index = month.findIndex(({ date }) => {
    if (date.getDate() === now.getDate() && date.getMonth() === now.getMonth()) {
      return true;
    }
  });
  // add today to shortcuts
  const shortcuts = [];
  if (index === -1) return shortcuts; // an error occured
  // split month by weeks
  const weeks = chunk(month, 7);
  // postion is the index of this week in this month
  const position = Math.floor(index / 7);
  // get next week
  let nextWeek = null;
  if (position === weeks.length - 1) {
    // today is in the last week of month
    const nextMonth = getDatesInMonthDisplay(now.getMonth() + 2, now.getFullYear());
    const nextMonthWeeks = chunk(nextMonth, 7);
    nextWeek = nextMonthWeeks[0]; // use first week of next month
  } else {
    nextWeek = weeks[position + 1]; // use next week
  }
  if (!nextWeek) return shortcuts;
  shortcuts.push({
    name: t('common.nextWeek'),
    startDate: nextWeek[0].date,
    endDate: nextWeek[6].date,
  });
  // push today
  shortcuts.push({
    name: t('common.today'),
    startDate: now,
    endDate: now,
  });
  // set this week timespan
  shortcuts.push({
    name: t('common.thisWeek'),
    startDate: weeks[position][0].date,
    endDate: now,
  });
  let lastWeek = null;
  if (position === 0) {
    // there is no previous week
    const lastMonth = getDatesInMonthDisplay(now.getMonth(), now.getFullYear());
    const lastMonthWeeks = chunk(lastMonth, 7);
    lastWeek = lastMonthWeeks[lastMonthWeeks.length - 1];
  } else {
    lastWeek = weeks[position - 1];
  }
  if (!lastWeek) return shortcuts; // an error occured
  shortcuts.push({
    name: t('common.lastWeek'),
    startDate: lastWeek[0].date,
    endDate: lastWeek[6].date,
  });
  // find days from start of month
  const startOfMonthIndex = month.findIndex(({ isCurrentMonth }) => isCurrentMonth);
  if (startOfMonthIndex === -1) return shortcuts; // an error occured
  shortcuts.push({
    name: t('common.thisMonth'),
    startDate: month[startOfMonthIndex].date,
    endDate: now,
  });
  return shortcuts;
};

export const getYears = () => {
  const thisYear = parseInt(moment(new Date()).format('YYYY'), 10);
  const years = [];
  for (let i = 0; i < 7; i++) {
    years.push(moment(new Date(thisYear - i, 1, 0, 0, 0, 0, 0)).format('YYYY'));
  }
  return years;
};
