import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RotateSpinner } from 'react-spinners-kit';
import { useDispatch } from 'react-redux';
import { setUser } from 'src/store/auth/actions';
import { RootState } from 'src/store';
import User from 'src/services/user';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--color-bg-regular);
`;

const Error = styled.h3`
  font-size: var(--font-size-h3);
  font-weight: 600;
  color: var(--color-grey-dark);
  text-align: center;
  margin: var(--margin-l);
  max-width: 500pt;
`;

export const Public = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const [error, setError] = React.useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const auth = async () => {
      if (!isAuthenticated) {
        try {
          const user = await User.getUser();
          if (user !== null) {
            if (new Date(user.expires_at * 1000) < new Date()) {
              // user has expired
              await User.removeUser();
              User.login().catch((e) => {
                setError(t('errors.loginRedirectFailed'));
              });
            } else {
              dispatch(setUser(user));
            }
          } else {
            User.login().catch(() => {
              setError(t('errors.loginRedirectFailed'));
            });
          }
        } catch (e) {
          setError(t('errors.getUserFromStorageFailed'));
        }
      }
    };
    auth();
  }, []);

  return (
    <Container>
      {error ? <Error>{error}</Error> : <RotateSpinner size={30} color="var(--color-blue)" loading />}
    </Container>
  );
};
