import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updateKpi } from 'src/store/system/actions';
import { RootState } from 'src/store';
import { Icon } from 'src/components/common';
import { abbreviateNumber } from '../../helpers/number';
import { useKpis } from 'src/data/hooks';
import ContentLoader from 'react-content-loader';

interface StyleProps {
  isPositive?: boolean;
}

const Grid = styled.div`
  margin-top: var(--margin-m);
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: var(--margin-l);
  @media (max-width: 1260px) and (min-width: 926px) {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: var(--margin-r);
    grid-column-gap: var(--margin-r);
  }
  @media (max-width: 925px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: var(--margin-r);
    grid-column-gap: var(--margin-r);
  }
`;

const Card = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  padding: var(--margin-r) var(--margin-r);
  background-color: var(--color-bg-light);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--border-radius-s);
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

const Name = styled.p`
  color: var(--color-grey-regular);
  font-weight: 500;
  font-size: 0.6em;
  height: 1.5em;
  margin-bottom: var(--margin-s);
`;

const Value = styled.h3`
  color: var(--color-grey-dark);
  font-weight: bold;
  font-size: var(--font-size-h3);
  margin-bottom: var(--margin-s);
`;

const DeltaRow = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  color: ${({ isPositive }) => (isPositive ? 'var(--color-green)' : 'var(--color-red)')};
  svg  {
    fill: ${({ isPositive }) => (isPositive ? 'var(--color-green)' : 'var(--color-red)')};
  }
  margin-bottom: var(--margin-xs);
`;

const Delta = styled.p`
  font-weight: 500;
  font-size: var(--font-size-s);
  margin-left: var(--margin-xs);
`;

const DeltaInfo = styled.p`
  color: var(--color-grey-regular);
  font-size: var(--font-size-xs);
  margin-left: var(--margin-s);
`;

const Indicator = styled.div`
  position: absolute;
  height: 3pt;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-blue);
`;

export const Kpis = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { endDate, activeKpi } = useSelector((state: RootState) => state.system);
  const { kpis } = useKpis();
  const onChangeAcitveKpi = (id: string) => dispatch(updateKpi(id));

  React.useEffect(() => {
    if (kpis.length > 0) {
      dispatch(updateKpi(kpis[0].kpi));
    }
  }, [kpis]);

  return (
    <Grid data-test-id="kpi-items">
      {kpis.map((item) => (
        <Card key={item.kpi} onClick={() => onChangeAcitveKpi(item.kpi)}>
          <Name>{t('kpis.' + item.kpi).toUpperCase()}</Name>
          <Value>{abbreviateNumber(item.value)}</Value>
          <DeltaRow isPositive={item.delta >= 0}>
            <Icon name={item.delta >= 0 ? 'caret-up' : 'caret-down'} size="14pt" />
            <Delta>{abbreviateNumber(Math.abs(item.delta))}</Delta>
            <DeltaInfo>{`${t('common.since')} ${endDate.getFullYear() - 1}`}</DeltaInfo>
          </DeltaRow>
          {item.kpi === activeKpi && <Indicator />}
        </Card>
      ))}
      {kpis.length === 0 &&
        Array.from(Array(6), (e, i) => i + 1).map((id) => (
          <Card key={id}>
            <ContentLoader style={{ height: '60pt', width: '100%' }}>
              <rect x="9" y="4" rx="0" ry="0" width="90%" height="22" />
              <rect x="11" y="33" rx="0" ry="0" width="60%" height="15" />
            </ContentLoader>
          </Card>
        ))}
    </Grid>
  );
};
