import React from 'react';
import styled from 'styled-components';
import { Bar } from 'src/components/common';

interface BarChartProps {
  type: 'number' | 'currency';
  value: number;
  percentage: string;
  name: string;
  rgb: string;
}

interface StyleProps {
  rgb: string;
}

const Row = styled.div`
  margin-top: var(--margin-s);
  margin-bottom: 2pt;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Name = styled.p`
  color: var(--color-grey-regular);
  font-size: var(--font-size-r);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Value = styled.p<StyleProps>`
  color: ${({ rgb }) => `rgb(${rgb})`};
  font-size: var(--font-size-r);
`;

export const BarChartItem: React.FC<BarChartProps> = ({ value, percentage, name, rgb, type }) => {
  const _value = React.useMemo(() => {
    if (type === 'number') return value;
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
  }, [value]);

  return (
    <>
      <Row>
        <Name>{name}</Name>
        <Value rgb={rgb}>{_value}</Value>
      </Row>
      <Bar percentage={percentage} rgb={rgb} />
    </>
  );
};
