import styled from 'styled-components';
import { Icon } from 'src/components/common';

interface StyleProps {
  isVisible?: boolean;
  height?: number;
}

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--margin-m) var(--margin-l) var(--margin-l);
  background-color: var(--color-bg-light);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--border-radius-s);
  min-height: 400px;
  position: relative;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--margin-xs);
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h3`
  color: var(--color-grey-dark);
  font-size: var(--font-size-xxl);
  font-weight: bold;
  user-select: none;
  @media (max-width: 1110px) {
    font-size: var(--font-size-l);
  }
`;

export const SubTitle = styled.p`
  color: var(--color-grey-dark);
  font-size: var(--font-size-xs);
`;

export const PaginationRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: var(--margin-r);
  left: 0;
  right: 0;
`;

export const PaginationDot = styled.div<StyleProps>`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ isVisible }) => (isVisible ? 'var(--color-grey-dark)' : 'var(--color-grey-light)')};
  margin: 0 var(--margin-xs);
`;

export const StyledIcon = styled(Icon)<StyleProps>`
  cursor: ${({ isVisible }) => (isVisible ? 'pointer' : undefined)};
  svg {
    stroke: ${({ isVisible }) => (isVisible ? 'var(--color-grey-dark)' : 'var(--color-grey-light)')};
  }
`;

export const BlurredContainer = styled.div`
  filter: blur(10px);
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--margin-l);
  border-radius: var(--border-radius-m);
  background-color: #ffffff33;
`;

export const NoDataPlaceholder = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--font-size-xxl);
  color: var(--color-grey-regular);
  font-weight: 600;
  text-align: center;
`;
