import React from 'react';
import chunk from 'lodash/chunk';
import moment from 'moment';
import {
  getDatesInMonthDisplay,
  getDayNames,
  getPrevMonthYear,
  getNextMonthYear,
  getDayDiff,
  isInFutureMonths,
  isInPastMonths,
  getShortcuts,
  getYears,
} from '../../helpers/calendar';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { updateDates } from 'src/store/system/actions';
import { RootState } from 'src/store';
import {
  Container,
  Wrapper,
  TitleRow,
  TitleContainer,
  StyledSelect,
  Month,
  StyledIcon,
  DaysRow,
  DayContainer,
  DayCircle,
  Day,
  DayLeftIndicator,
  DayRightIndicator,
  DayIndicator,
  StyledDivider,
  ButtonRow,
  Button,
} from './calendar.style';
import { useTranslation } from 'react-i18next';

export const Calendar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const system = useSelector((state: RootState) => state.system);
  const [year, setYear] = React.useState(system.endDate.getFullYear());
  const [month, setMonth] = React.useState(system.endDate.getMonth() + 1);
  const [dates, setDates] = React.useState(getDatesInMonthDisplay(month, year));
  const [days] = React.useState(getDayNames(dates));
  const [years] = React.useState(getYears());
  const [shortcuts] = React.useState(getShortcuts(t));

  const onIncrementMonth = (): void => {
    const obj = getNextMonthYear(month, year);
    setYear(obj.year);
    setMonth(obj.month);
    setDates(getDatesInMonthDisplay(obj.month, obj.year));
  };

  const onDecrementMonth = (): void => {
    const obj = getPrevMonthYear(month, year);
    setYear(obj.year);
    setMonth(obj.month);
    setDates(getDatesInMonthDisplay(obj.month, obj.year));
  };

  const onClickDate = (date: Date): void => {
    const startDayDiff = getDayDiff(system.endDate, date);
    const endDayDiff = getDayDiff(system.startDate, date);
    const momentD = moment(date);
    const format = 'Do MMM';
    const dateYear = date.getFullYear();
    const thisYear = new Date().getFullYear();
    const year = dateYear != thisYear ? ` | ${momentD.format('YYYY')}` : '';

    if (startDayDiff === 0 || endDayDiff === 0) {
      // user selected an already set date
      dispatch(
        updateDates({
          dateShortcutName: momentD.format(format),
          endDate: date,
          startDate: date,
        }),
      );
    } else if (Math.abs(startDayDiff) > 335 || Math.abs(endDayDiff) > 335) {
      // user selected a date that is more than 11 Months apart from the
      // previous selection this means he propbably selected a new year
      dispatch(
        updateDates({
          dateShortcutName: momentD.format(format) + year,
          endDate: date,
          startDate: date,
        }),
      );
    } else if (startDayDiff > 0 && endDayDiff < 0) {
      // user clicked in between the selected dates => find closest
      if (Math.abs(startDayDiff) < Math.abs(endDayDiff)) {
        dispatch(
          updateDates({
            dateShortcutName: `${momentD.format(format)} - ${moment(system.endDate).format(format)}` + year,
            endDate: system.endDate,
            startDate: date,
          }),
        );
      } else {
        dispatch(
          updateDates({
            dateShortcutName: `${moment(system.startDate).format(format)} - ${momentD.format(format)}` + year,
            startDate: system.startDate,
            endDate: date,
          }),
        );
      }
    } else if (endDayDiff < 0) {
      // date is greater than endDate
      dispatch(
        updateDates({
          dateShortcutName: `${moment(system.startDate).format(format)} - ${momentD.format(format)}` + year,
          startDate: system.startDate,
          endDate: date,
        }),
      );
    } else if (startDayDiff > 0) {
      // date is smaller than startDate
      dispatch(
        updateDates({
          dateShortcutName: `${momentD.format(format)} - ${moment(system.endDate).format(format)}` + year,
          endDate: system.endDate,
          startDate: date,
        }),
      );
    }
  };

  const renderPeriodIndicators = (date: Date): JSX.Element | null => {
    // render the date connectors
    const isSameDay = getDayDiff(system.endDate, system.startDate) === 0;
    const startDayDiff = getDayDiff(system.startDate, date);
    const endDayDiff = getDayDiff(system.endDate, date);

    if (startDayDiff > 0 || endDayDiff < 0 || isSameDay) {
      return null;
    }
    if (startDayDiff === 0 && endDayDiff !== 0) {
      return <DayRightIndicator />;
    }
    if (startDayDiff !== 0 && endDayDiff === 0) {
      return <DayLeftIndicator />;
    }
    if (endDayDiff > 0 && startDayDiff < 0) {
      return <DayIndicator />;
    }
    return null;
  };

  const onClickShortcut = (item): void => {
    const _year = item.startDate.getFullYear();
    const _month = item.startDate.getMonth() + 1;
    setYear(_year);
    setMonth(_month);
    setDates(getDatesInMonthDisplay(_month, _year));
    dispatch(
      updateDates({
        dateShortcutName: item.name,
        startDate: item.startDate,
        endDate: item.endDate,
      }),
    );
  };

  const onChange = (event) => {
    setYear(event.target.value);
    setDates(getDatesInMonthDisplay(month, event.target.value));
  };

  return (
    <Container data-test-id="calendar-modal">
      <Wrapper>
        <TitleRow>
          <StyledIcon
            name="arrow-back"
            size="20pt"
            onClick={onDecrementMonth}
            isActive={isInPastMonths(system.startDate, year, month)}
          />
          <TitleContainer>
            <Month>{moment(new Date(year, month, 0, 0, 0, 0, 0)).format('MMMM')}</Month>
            <StyledSelect onChange={onChange} value={year}>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </StyledSelect>
          </TitleContainer>
          <StyledIcon
            name="arrow-forward"
            size="20pt"
            onClick={onIncrementMonth}
            isActive={isInFutureMonths(system.endDate, year, month)}
          />
        </TitleRow>
        <DaysRow>
          {days.map((day) => (
            <DayContainer key={day}>
              <Day>{day}</Day>
            </DayContainer>
          ))}
        </DaysRow>
        <StyledDivider />
        {chunk(dates, 7).map((row, i) => (
          <DaysRow key={i}>
            {row.map((item) => (
              <DayContainer key={item.date}>
                {renderPeriodIndicators(item.date)}
                <DayCircle
                  onClick={() => onClickDate(item.date)}
                  isActive={
                    getDayDiff(item.date, system.startDate) === 0 || getDayDiff(item.date, system.endDate) === 0
                  }
                  isHighlighted={
                    getDayDiff(item.date, system.startDate) > 0 && getDayDiff(item.date, system.endDate) < 0
                  }
                  isCurrentMonth={item.isCurrentMonth}
                >
                  <Day>{moment(item.date).format('D')}</Day>
                </DayCircle>
              </DayContainer>
            ))}
          </DaysRow>
        ))}
        <ButtonRow>
          {shortcuts.map((item) => (
            <Button onClick={() => onClickShortcut(item)} key={item.name}>
              {item.name}
            </Button>
          ))}
        </ButtonRow>
      </Wrapper>
    </Container>
  );
};
