export const DEFAULT_REVENUES = [
  {
    label: 'Concession',
    data: [
      [0, 1],
      [1, 2],
      [2, 4],
      [3, 2],
      [4, 7],
    ],
    color: 'var(--color-purple)',
  },
  {
    label: 'Ticket',
    data: [
      [0, 3],
      [1, 1],
      [2, 5],
      [3, 6],
      [4, 4],
    ],
    color: 'var(--color-blue)',
  },
];

export const DEFAULT_CENTER_KPIS = [
  {
    name: 'Stuttgart',
    value: 45,
    centerId: '10000000014RUTSLOC',
  },
  {
    name: 'Essen',
    value: 43,
    centerId: '20000000014RUTSLOC',
  },
  {
    name: 'Frankfurt am Main',
    value: 36,
    centerId: '30000000014RUTSLOC',
  },
  {
    name: 'Köln',
    value: 25,
    centerId: '40000000014RUTSLOC',
  },
  {
    name: 'Dortmund',
    value: 25,
    centerId: '50000000014RUTSLOC',
  },
  {
    name: 'Hannover',
    value: 18,
    centerId: '60000000014RUTSLOC',
  },
  {
    name: 'Düsseldorf',
    value: 12,
    centerId: '70000000014RUTSLOC',
  },
  {
    name: 'Hamburg',
    value: 9,
    centerId: '80000000014RUTSLOC',
  },
  {
    center: 'München',
    value: 6,
    centerId: '90000000014RUTSLOC',
  },
];

export const DEFAULT_MOVIE_KPIS = [
  {
    filmId:'10000000572RUTSLOC',
    filmReleaseId:'10000000012RUTSLOC',
    name: '25 km/h - Digital',
    value: 9,
  },
  {
    filmId:'20000000572RUTSLOC',
    filmReleaseId:'20000000012RUTSLOC',
    name: 'A Beautiful Planet - IMAX 3D',
    value: 51,
  },
  {
    filmId:'30000000572RUTSLOC',
    name: 'A Quiet Place Part II',
    value: 26,
  },
  {
    filmId:'40000000572RUTSLOC',
    filmReleaseId:'40000000012RUTSLOC',
    name: 'Aladdin - Atmos',
    value: 205,
  },
  {
    filmId:'50000000572RUTSLOC',
    name: 'Alles voor Elkaar',
    value: 0,
  },
  {
    filmId:'60000000572RUTSLOC',
    name: 'American Assassin',
    value: 0,
  },
  {
    filmId:'70000000572RUTSLOC',
    filmReleaseId:'70000000012RUTSLOC',
    name: 'BANG BOOM BANG - Digital',
    value: 7,
  },
  {
    filmId:'80000000572RUTSLOC',
    filmReleaseId:'80000000012RUTSLOC',
    name: 'Bailey - Ein Hund kehrt zur\u00fcck - Digital',
    value: 43,
  },
  {
    filmId:'90000000572RUTSLOC',
    name: "Bella Donna's",
    value: 0,
  },
];
