import Oidc from 'oidc-client';
import { SET_AUTH, SET_EXPIRED, AuthActionTypes } from './types';

export function setUser(user: Oidc.User): AuthActionTypes {
  return { type: SET_AUTH, payload: user };
}

export function setExpired(): AuthActionTypes {
  return { type: SET_EXPIRED };
}
