import { SelectedDatesInterface, SystemActionTypes, UPDATE_DATES, UPDATE_CENTERS, UPDATE_KPI } from './types';
import { CenterInterface } from 'src/data/types';

export function updateDates(dates: SelectedDatesInterface): SystemActionTypes {
  return {
    type: UPDATE_DATES,
    payload: dates,
  };
}

export function updateCenters(centers: Array<CenterInterface>): SystemActionTypes {
  return {
    type: UPDATE_CENTERS,
    payload: centers,
  };
}

export function updateKpi(kpi: string): SystemActionTypes {
  return {
    type: UPDATE_KPI,
    payload: kpi,
  };
}
