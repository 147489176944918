import Oidc from 'oidc-client';
export const SET_AUTH = 'SET_USER';
export const SET_EXPIRED = 'SET_EXPIRED';

export interface AuthState {
  isAuthenticated: boolean;
  idToken: string;
  accessToken: string;
  expiresAt: Date;
}

interface SetAuthAction {
  type: typeof SET_AUTH;
  payload: Oidc.User;
}

interface SetExpiredAction {
  type: typeof SET_EXPIRED;
}

export type AuthActionTypes = SetAuthAction | SetExpiredAction;
