import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Input, Icon } from 'src/components/common';
import { RootState } from 'src/store';
import { CenterInterface } from 'src/data/types';
import { DropdownItem } from './dropdown-item';
import { useTranslation } from 'react-i18next';
import { updateCenters } from 'src/store/system/actions';

interface DropdownProps {
  items: Array<CenterInterface>;
}

interface StyleProps {
  isVisible: boolean;
}

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const DropdownContainer = styled.div<StyleProps>`
  position: relative;
  display: inline-block;
  :hover {
    .drop-down-content {
      display: flex;
      flex-direction: column;
    }
  }
  ${(props) =>
    props.isVisible &&
    `
    .drop-down-content {
      display: flex;
      flex-direction: column;
    }
  `}
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 0;
  top: calc(var(--margin-s) + 1pt);
  svg {
    display: block;
    stroke: var(--color-blue);
  }
  @media (max-width: 625px) {
    top: var(--margin-xs);
  }
`;

const DropdownTextContainer = styled.div`
  display: flex;
  position: relative;
  margin-left: var(--margin-r);
  padding-right: var(--margin-l);
  padding-bottom: var(--margin-s);
  padding-top: var(--margin-s);
  @media (max-width: 625px) {
    margin-left: 0;
    padding-top: var(--margin-xs);
  }
`;

const DropdownText = styled.span`
  font-size: var(--font-size-r);
  color: var(--color-blue);
  font-weight: 600;
`;
const DropdownWrapper = styled.div`
  position: relative;
`;
const DropdownContent = styled.div`
  display: none;
  position: absolute;
  border-radius: var(--border-radius-m);
  background-color: var(--color-bg-light);
  left: -40pt;
  width: 180pt;
  max-width: 95vh;
  box-shadow: 2pt 12pt 54pt rgba(0, 0, 0, 0.14);
  z-index: 1;
  max-height: 80vh;
  overflow-y: auto;
  @media (max-width: 625px) {
    left: 0;
  }
`;

const Searchbar = styled(Input)`
  margin: var(--margin-r);
`;

const ResetButton = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-blue);
  box-shadow: 0 -4pt 8pt rgba(0, 0, 0, 0.08);
  cursor: pointer;
`;

const ResetButtonText = styled.p`
  font-size: var(--font-size-s);
  font-weight: 600;
  color: var(--color-bg-light);
  padding: var(--margin-r) 0;
  text-align: center;
  align-self: center;
`;

export const Dropdown: React.FC<DropdownProps> = ({ items }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<string>('');
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const centers = useSelector((state: RootState) => state.system.centers);
  const dispatch = useDispatch();

  const toggleIsVisible = () => setIsVisible(!isVisible);
  const onChangeValue = (val: string): void => setValue(val);
  const onResetCenters = () => dispatch(updateCenters(items));

  return (
    <>
      {isVisible && <AbsoluteContainer onClick={toggleIsVisible} />}
      <DropdownContainer isVisible={isVisible} data-test-id="center-select-dropdown">
        <DropdownTextContainer onClick={toggleIsVisible}>
          <DropdownText>
            {t(centers.length === items.length ? 'dashboard.allCenters' : 'dashboard.editCenters')}
          </DropdownText>
          <StyledIcon name="chevron-down" size="13pt" />
        </DropdownTextContainer>
        <DropdownContent className="drop-down-content">
          {items.length > 8 && (
            <div>
              <Searchbar label={t('common.search')} value={value} onChange={onChangeValue} />
              <Divider />
            </div>
          )}
          <DropdownWrapper>
            {items
              .filter(({ name }) => name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || value.length <= 1) // search
              .map((item) => (
                <DropdownItem item={item} key={item.id} />
              ))}
            {items.length > 3 && (
              <ResetButton onClick={onResetCenters}>
                <ResetButtonText>{t('common.reset')}</ResetButtonText>
              </ResetButton>
            )}
          </DropdownWrapper>
        </DropdownContent>
      </DropdownContainer>
    </>
  );
};
