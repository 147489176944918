import { Icon } from 'src/components/common';
import styled from 'styled-components';
import { Chart } from 'react-charts';

interface StyleProps {
  color?: string;
  isblurry?: boolean;
}

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--margin-l);
  padding: var(--margin-m) var(--margin-l);
  background-color: var(--color-bg-light);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.04);
  border-radius: var(--border-radius-s);
  height: 300px;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--margin-r);
`;

export const UnitInfoText = styled.p`
  margin-top: var(--margin-xs);
  font-size: var(--font-size-s);
  color: var(--color-grey-regular);
  margin-left: var(--margin-r);
  @media (max-width: 625px) {
    margin-left: 0;
    margin-top: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendRow = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  align-items: center;
  @media (max-width: 625px) {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: var(--margin-xs);
  }
`

export const ColorIndicator = styled.div<StyleProps>`
  display: block;
  height: 8pt;
  width: 8pt;
  border-radius: 4pt;
  background-color: ${({ color }) => color};
  margin: 0 var(--margin-s);
`;

export const IndicatorText = styled.p`
  font-size: var(--font-size-s);
  font-weight: 500;
  color: var(--color-grey-regular);
  margin-right: var(--margin-s);
`;

export const Title = styled.h3`
  color: var(--color-grey-dark);
  font-size: var(--font-size-xxl);
  font-weight: bold;
  user-select: none;
  @media (max-width: 1110px) {
    font-size: var(--font-size-l);
  }
`;
export const ChartContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
`;

export const StyledChart = styled(Chart) <StyleProps>`
  filter: ${({ isblurry }) => (isblurry ? 'blur(10px)' : undefined)};
  text.tickLabel {
    font-size: var(--font-size-s) !important;
    font-weight: 600 !important;
    fill: var(--color-grey-regular) !important;
    font-family: inherit !important;
  }
  line.gridLine {
    stroke-dasharray: 10 7 !important;
    stroke: var(--color-grey-light);
  }
  g.labelGroup .tickline {
    opacity: 0 !important;
  }
  g.axes g.Axis:last-child line.gridLine {
    opacity: 0 !important;
  }
  g.axes g.Axis:last-child .tickLabel {
    transform: translate3d(-15px, 0px, 0px) !important;
  }
  path.domain {
    opacity: 0 !important;
  }
  .axes {
    transform: translate3d(-5px, 0px, 0px) !important;
  }
  .Series {
    transform: translate3d(-5px, 0px, 0px) !important;
  }
`;

export const NoDataPlaceholder = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: var(--font-size-xxl);
  color: var(--color-grey-regular);
  font-weight: 600;
  text-align: center;
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledResetIcon = styled(Icon)`
  margin-left: var(--margin-r);
  cursor: pointer;
  svg {
    fill: var(--color-blue);
  }
`;
