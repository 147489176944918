import { CenterInterface } from 'src/data/types';

export const UPDATE_DATES = 'UPDATE_DATES';
export const UPDATE_CENTERS = 'UPDATE_CENTERS';
export const UPDATE_KPI = 'UPDATE_KPI';

export interface SystemState {
  centers: Array<CenterInterface>;
  dateShortcutName: string;
  startDate: Date;
  endDate: Date;
  activeKpi: string;
}

export interface SelectedDatesInterface {
  dateShortcutName: string;
  startDate: Date;
  endDate: Date;
}

interface UpdateDatesAction {
  type: typeof UPDATE_DATES;
  payload: SelectedDatesInterface;
}

interface UpdateCentersAction {
  type: typeof UPDATE_CENTERS;
  payload: Array<CenterInterface>;
}

interface UpdateKpiAction {
  type: typeof UPDATE_KPI;
  payload: string;
}

export type SystemActionTypes = UpdateDatesAction | UpdateCentersAction | UpdateKpiAction;
