import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { VectorGraphic } from 'src/components/common';

const TITLE = 'BI Dashboard';

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
`;

const Absolute = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Graphic = styled(VectorGraphic)`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 4.3rem;
  color: var(--color-grey-dark);
  font-weight: 700;
  font-family: 'Montserrat';
  @media (max-width: 820px) {
    font-size: 3rem;
  }
  @media (max-width: 550px) {
    font-size: 2.4rem;
  }
`;

const Subtitle = styled.h6`
  font-size: 1.2rem;
  color: var(--color-blue);
  font-weight: 600;
  font-family: 'Montserrat' sans-serif;
  margin-bottom: var(--margin-xxl);
  @media (max-width: 550px) {
    font-size: 1rem;
  }
`;

export const Home = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <Container>
        <Title>{TITLE}</Title>
        <Subtitle>{t('home.byCompeso').toUpperCase()}</Subtitle>
      </Container>
      <Absolute>
        <Graphic name="skyline" width="60%" height="60%" />
      </Absolute>
    </StyledContainer>
  );
};
