import styled from 'styled-components';
import { Icon, Divider } from 'src/components/common';

interface StyleProps {
  isActive?: boolean;
  isHighlighted?: boolean;
  isCurrentMonth?: boolean;
}

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90vw;
  background-color: var(--color-bg-light);
  border-radius: var(--border-radius-m);
  box-shadow: 0px 0px 20px #00000033;
  animation-name: animatebottom;
  animation-duration: 0.3s;

  @keyframes animatebottom {
    from {
      top: 60%;
      opacity: 0;
    }
    to {
      top: 50%;
      opacity: 1;
    }
  }
`;

const Wrapper = styled.div`
  margin: var(--margin-xl) var(--margin-m);
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: var(--margin-m);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledSelect = styled.select`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  appearance: none;
  background-color: transparent; /* Fallback color if gradients are not supported */
  border: none;
  border-radius: 2px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  margin: 0;
  overflow: hidden;
  margin-top: var(--margin-xs);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--font-size-r);
  font-weight: 600;
  color: var(--color-blue);
  cursor: pointer;
  box-shadow: none;
  :focus {
    outline: none;
  }
  ::-ms-expand {
    display: none;
  }
`;

const Month = styled.div`
  font-size: var(--font-size-xxl);
  font-weight: bold;
  color: var(--color-grey-dark);
  user-select: none;
`;

const StyledIcon = styled(Icon)<StyleProps>`
  cursor: pointer;
  svg {
    stroke: ${(props) => (props.isActive ? 'var(--color-blue)' : 'var(--color-grey-dark)')};
  }
`;

const DaysRow = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  margin-bottom: var(--margin-r);
`;

const DayContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  position: relative;
`;

const DayCircle = styled.div<StyleProps>`
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.isActive ? '#4B7BE5' : 'transparent')};
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  p {
    color: ${({ isActive, isHighlighted, isCurrentMonth }) => {
      if (isActive || isHighlighted) return 'var(--color-bg-light)';
      if (!isCurrentMonth) return 'var(--color-grey-light)';
      return 'var(--color-grey-regular)';
    }};
  }
`;

const Day = styled.p`
  font-weight: 500;
  text-align: center;
  font-size: var(--font-size-s);
  color: var(--color-grey-regular);
`;

const DayLeftIndicator = styled.div`
  position: absolute;
  /*fix white space */
  left: -1px;
  top: 0;
  bottom: 0;
  background-color: var(--color-blue);
  width: 50%;
  z-index: 1;
`;

const DayRightIndicator = styled.div`
  position: absolute;
  /*fix white space */
  right: -1px;
  top: 0;
  bottom: 0;
  background-color: var(--color-blue);
  width: 50%;
  z-index: 1;
`;
const DayIndicator = styled.div`
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  left: -1px;
  background-color: var(--color-blue);
  z-index: 1;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: var(--margin-r);
`;

const ButtonRow = styled.div`
  overflow: auto;
  white-space: nowrap;
`;

const Button = styled.button`
  display: inline-block;
  height: 2rem;
  border: none;
  padding: 0 var(--margin-m);
  background-color: var(--color-grey-light);
  color: var(--color-grey-dark);
  border-radius: 1rem;
  font-size: var(--font-size-s);
  font-weight: 500;
  margin: var(--margin-m) var(--margin-s) var(--margin-s) 0;
  cursor: pointer;
  :focus {
    outline: 0;
  }
`;

export {
  Container,
  Wrapper,
  TitleRow,
  TitleContainer,
  StyledSelect,
  Month,
  StyledIcon,
  DaysRow,
  DayContainer,
  DayCircle,
  Day,
  DayLeftIndicator,
  DayRightIndicator,
  DayIndicator,
  StyledDivider,
  ButtonRow,
  Button,
};
