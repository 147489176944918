import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Home } from 'src/scenes/home/home';
import { Dashboard } from 'src/scenes/dashboard/dashboard';
import { SideMenu } from 'src/components/menu';
import { NotFound, UnexpectedError } from 'src/scenes/error';
import { AuthCallback, Public } from 'src/scenes/auth';

export const Router = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  return (
    <BrowserRouter>
      {isAuthenticated ? (
        <SideMenu>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/unexpected-error" component={UnexpectedError} />
            <Route path="/auth-callback" component={AuthCallback} />
            <Route path="*" component={NotFound} />
          </Switch>
        </SideMenu>
      ) : (
        <Switch>
          <Route exact path="/" component={Public} />
          <Route path="/auth-callback" component={AuthCallback} />
          <Redirect from="*" to="/" />
        </Switch>
      )}
    </BrowserRouter>
  );
};
