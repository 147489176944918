import { AuthState, AuthActionTypes, SET_AUTH, SET_EXPIRED } from './types';

const initialState: AuthState = {
  isAuthenticated: false,
  idToken: '',
  accessToken: '',
  expiresAt: new Date(),
};

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case SET_AUTH: {
      return {
        isAuthenticated: true,
        idToken: action.payload.id_token,
        accessToken: action.payload.access_token,
        expiresAt: new Date(action.payload.expires_at * 1000),
      };
    }
    case SET_EXPIRED: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
