import React, { useState } from 'react';
import { BarChartItem } from './bar-chart-item';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useCenterKpis } from 'src/data/hooks';
import { useTranslation } from 'react-i18next';
import { RotateSpinner } from 'react-spinners-kit';
import {
  Card,
  TitleRow,
  Column,
  Title,
  SubTitle,
  PaginationRow,
  PaginationDot,
  StyledIcon,
  BlurredContainer,
  SpinnerContainer,
  NoDataPlaceholder,
} from './center-details.style';
import { DEFAULT_CENTER_KPIS } from '../../constants';
import { useLayout } from 'src/helpers/hooks';
import { PieChart } from './pie-chart';

const PAGE_SIZE = 9;

const COLORS = ['#48CF8F', '#6691EF', '#8A60D9', '#FA8080', '#3ADCE3'];

export const CenterDetails: React.FC = () => {
  const ref = React.useRef(null);
  const { t } = useTranslation();
  const { centerKpis: items, isLoading } = useCenterKpis();
  const [offset, setOffset] = React.useState<number>(0);
  const { activeKpi } = useSelector((state: RootState) => state.system);
  const [height, setHeight] = useState(0);
  const { height: itemHeight } = useLayout(ref);

  React.useEffect(() => {
    height === 0 && setHeight(itemHeight);
  }, [itemHeight]);

  React.useEffect(() => {
    if (offset >= items.length) {
      // page doesn't exist
      setOffset(0);
    }
  }, [items]);

  const max = React.useMemo(() => {
    const val = Math.max(...items.map((el) => el.value));
    if (val !== 0) return val; // can't divide by 0 somehow :D
    return 1;
  }, [items]);

  const onIncrement = (): void => {
    const nextOffset = offset + PAGE_SIZE;
    if (nextOffset <= items.length - 1) {
      setOffset(nextOffset);
    }
  };

  const onDecrement = (): void => {
    const prevOffset = offset - PAGE_SIZE;
    if (prevOffset >= 0) {
      setOffset(prevOffset);
    }
  };

  const data = React.useMemo(() => {
    if (items.length > 0 && items.length <= 6) {
      return items.map(({ name, value }, index) => ({
        id: name,
        label: name,
        value: value,
        color: COLORS[index],
      }));
    }
    return [];
  }, [items]);

  return (
    <Card ref={ref} data-test-id="center-detail-kpis">
      <TitleRow>
        <StyledIcon name="arrow-back" size="20pt" onClick={onDecrement} isVisible={offset !== 0} />
        <Column>
          <Title>{t('common.center')}</Title>
          <SubTitle>{activeKpi ? t(`kpis.${activeKpi}`) : ''}</SubTitle>
        </Column>
        <StyledIcon
          name="arrow-forward"
          size="20pt"
          onClick={onIncrement}
          isVisible={items.length - offset > PAGE_SIZE}
        />
      </TitleRow>
      {items.length > COLORS.length &&
        items
          .slice(offset, offset + PAGE_SIZE)
          .map(({ name, value, centerId }) => (
            <BarChartItem
              type={activeKpi === 'totalTickets' ? 'number' : 'currency'}
              key={centerId ?? `${name}${value}`}
              name={name}
              rgb="var(--color-blue-rgb)"
              value={value}
              percentage={`${(value / max) * 100}%`}
            />
          ))}
      {items.length > 0 && items.length <= COLORS.length && <PieChart data={data} height={height} />}
      {items.length === 0 && (
        <>
          <BlurredContainer>
            {DEFAULT_CENTER_KPIS.map(({ name, value, centerId }) => (
              <BarChartItem
                type="number"
                key={`${centerId}`}
                name={name || ''}
                rgb="var(--color-blue-rgb)"
                value={value}
                percentage={`${value}%`}
              />
            ))}
          </BlurredContainer>
          {!isLoading && <NoDataPlaceholder>{t('common.noData')}</NoDataPlaceholder>}
        </>
      )}
      <PaginationRow>
        {items.length > PAGE_SIZE &&
          Array.from(Array(Math.ceil(items.length / PAGE_SIZE)), (e, i) => i + 1).map((key, i) => (
            <PaginationDot key={key} isVisible={offset === PAGE_SIZE * i} />
          ))}
      </PaginationRow>
      {isLoading && (
        <SpinnerContainer>
          <RotateSpinner size={30} color="var(--color-blue)" loading />
        </SpinnerContainer>
      )}
    </Card>
  );
};
