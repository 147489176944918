import React from 'react';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';

interface PieChartProps {
  data: any;
  height: number;
}

interface StyleProps {
  color?: string;
  height?: number;
}

const PieChartContainer = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? `${height}px` : '0')};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ColorIndicator = styled.div<StyleProps>`
  display: flex;
  height: 0.6rem;
  min-height: 0.6rem;
  width: 0.6rem;
  min-width: 0.6rem;
  border-radius: 0.6rem;
  background-color: ${({ color }) => color};
  margin: var(--marign-s) var(--margin-s);
`;

const IndicatorText = styled.p`
  font-size: var(--font-size-s);
  font-weight: 500;
  color: var(--color-grey-regular);
  margin: 0 var(--margin-s);
  text-align: center;
`;

const Tooltip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TooltipColorIndicator = styled.div<StyleProps>`
  display: flex;
  height: 0.6rem;
  min-height: 0.6rem;
  width: 0.6rem;
  min-width: 0.6rem;
  border-radius: 0.6rem;
  border: 2px solid #ffffff;
  background-color: ${({ color }) => color};
`;

const TooltipText = styled.p`
  font-size: var(--font-size-s);
  font-weight: 700;
  color: #ffffff;
  margin-left: var(--margin-s);
`;

export const PieChart: React.FC<PieChartProps> = ({ data, height }) => {
  const _height = React.useMemo(() => height - 50, [height]);
  const getColor = (item) => item.color;

  const renderTooltip = (item) => (
    <Tooltip>
      <TooltipColorIndicator color={item.color} />
      <TooltipText>{`${item.label}:  ${item.value}`}</TooltipText>
    </Tooltip>
  );
  return (
    <PieChartContainer height={_height}>
      <ResponsivePie
        data={data}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={getColor}
        margin={{ top: 30, right: 5, bottom: 30, left: 5 }}
        tooltip={renderTooltip}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        theme={{
          tooltip: {
            container: {
              background: '#222222',
            },
          },
        }}
        enableRadialLabels={false}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#375066"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#375066"
        motionStiffness={90}
        motionDamping={15}
      />
      <Row>
        {data.map(({ label, color, id }) => (
          <div key={id}>
            <ColorIndicator color={color} />
            <IndicatorText>{label}</IndicatorText>
          </div>
        ))}
      </Row>
    </PieChartContainer>
  );
};
