import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setUser } from 'src/store/auth/actions';
import Oidc from 'oidc-client';
import { useTranslation } from 'react-i18next';
import { RotateSpinner } from 'react-spinners-kit';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useHistory } from 'react-router-dom';
import * as logger from 'src/services/log';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--color-bg-regular);
`;

const Error = styled.h3`
  font-size: var(--font-size-h3);
  font-weight: 600;
  color: var(--color-grey-dark);
  text-align: center;
  margin: var(--margin-l);
  max-width: 500pt;
`;

export const AuthCallback = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = React.useState(false);
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const { push } = useHistory();

  React.useEffect(() => {
    if (!isAuthenticated) {
      new Oidc.UserManager({ response_mode: 'query' })
        .signinRedirectCallback()
        .then((user) => {
          logger.info(`User logged in successfully (centergroup: ${user.profile?.centergroup ?? 'undefined'})`);
          dispatch(setUser(user));
          push('/');
        })
        .catch(() => {
          setError(true);
        });
    }
  }, []);

  return (
    <Container>
      {error ? (
        <Error>{t('errors.loginCallbackError')}</Error>
      ) : (
        <RotateSpinner size={30} color="var(--color-blue)" loading />
      )}
    </Container>
  );
};
