import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'src/components/common';
import { CenterInterface } from 'src/data/types';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { updateCenters } from 'src/store/system/actions';

interface DropdownItemProps {
  item: CenterInterface;
}

interface StyleProps {
  checked: boolean;
}

const Row = styled.div<StyleProps>`
  background-color: ${({ checked }) => (checked ? 'var(--color-bg-regular)' : 'var(--color-bg-light)')};
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  padding: var(--margin-r);
`;

const Text = styled.p`
  font-size: var(--font-size-r);
  color: var(--color-grey-dark);
  font-weight: 600;
  margin-left: var(--margin-r);
  margin-bottom: 2pt;
  user-select: none;
  cursor: default;
`;

export const DropdownItem: React.FC<DropdownItemProps> = ({ item }) => {
  const centers = useSelector((state: RootState) => state.system.centers);
  const checked = centers.findIndex(({ id }) => id === item.id) !== -1;
  const dispatch = useDispatch();

  const handleCheckboxChange = () => {
    if (checked) {
      // add item
      const _centers = [...centers];
      const index = centers.findIndex(({ id }) => id === item.id);
      _centers.splice(index, 1);
      dispatch(updateCenters(_centers));
    } else {
      // remove item
      dispatch(updateCenters([...centers, item]));
    }
  };

  const handeDoupleClick = () => dispatch(updateCenters([item]));

  return (
    <Row checked={checked} onDoubleClick={handeDoupleClick}>
      <Checkbox checked={checked} onClick={handleCheckboxChange} className="" />
      <Text>{item.name}</Text>
    </Row>
  );
};
