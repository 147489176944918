import React from 'react';
import { BarChartItem } from './bar-chart-item';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useMovieKpis } from 'src/data/hooks';
import { useTranslation } from 'react-i18next';
import { RotateSpinner } from 'react-spinners-kit';
import { DEFAULT_MOVIE_KPIS } from '../../constants';
import {
  Card,
  TitleRow,
  Column,
  Title,
  SubTitle,
  PaginationRow,
  PaginationDot,
  StyledIcon,
  BlurredContainer,
  SpinnerContainer,
  NoDataPlaceholder,
} from './movie-details.style';

const PAGE_SIZE = 9;

export const MovieDetails: React.FC = () => {
  const { t } = useTranslation();
  const { movieKpis: items, isLoading } = useMovieKpis();
  const [offset, setOffset] = React.useState<number>(0);
  const { activeKpi } = useSelector((state: RootState) => state.system);

  const title = React.useMemo(() => {
    if (activeKpi === 'totalConcessionRevenue') {
      return t('common.concession');
    } else if (activeKpi === 'averageConcessionPurchase' || activeKpi === 'totalVoucherRevenue') {
      return '';
    } else {
      return t('common.movies');
    }
  }, [activeKpi]);

  const subTitle = React.useMemo(() => {
    if (!activeKpi || activeKpi === 'averageConcessionPurchase' || activeKpi === 'totalVoucherRevenue') {
      return '';
    }
    return t(`kpis.${activeKpi}`);
  }, [activeKpi]);

  React.useEffect(() => {
    if (offset >= items.length) {
      // page doesn't exist
      setOffset(0);
    }
  }, [items]);

  const max = React.useMemo(() => {
    const val = Math.max(...items.map((el) => el.value));
    if (val !== 0) return val; // can't divide by 0 somehow :D
    return 1;
  }, [items]);

  const onIncrement = (): void => {
    const nextOffset = offset + PAGE_SIZE;
    if (nextOffset <= items.length - 1) {
      setOffset(nextOffset);
    }
  };

  const onDecrement = (): void => {
    const prevOffset = offset - PAGE_SIZE;
    if (prevOffset >= 0) {
      setOffset(prevOffset);
    }
  };

  return (
    <Card data-test-id="movie-detail-kpis">
      <TitleRow>
        <StyledIcon name="arrow-back" size="20pt" onClick={onDecrement} isVisible={offset !== 0} />
        <Column>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </Column>
        <StyledIcon
          name="arrow-forward"
          size="20pt"
          onClick={onIncrement}
          isVisible={items.length - offset > PAGE_SIZE}
        />
      </TitleRow>
      {items.slice(offset, offset + PAGE_SIZE).map(({ name, value, filmId, filmReleaseId }) => (
        <BarChartItem
          type={activeKpi === 'totalTickets' ? 'number' : 'currency'}
          key={`${filmReleaseId ?? filmId ?? name}`}
          name={name}
          rgb="var(--color-green-rgb)"
          value={value}
          percentage={`${(value / max) * 100}%`}
        />
      ))}
      {items.length === 0 && (
        <>
          <BlurredContainer>
            {DEFAULT_MOVIE_KPIS.map(({ name, value, filmId, filmReleaseId }) => (
              <BarChartItem
                type="number"
                key={`${filmReleaseId ?? filmId ?? name}`}
                name={name || ''}
                rgb="var(--color-green-rgb)"
                value={value}
                percentage={`${value}%`}
              />
            ))}
          </BlurredContainer>
          {!isLoading && <NoDataPlaceholder>{t('common.noData')}</NoDataPlaceholder>}
        </>
      )}
      <PaginationRow>
        {items.length > PAGE_SIZE &&
          Array.from(Array(Math.ceil(items.length / PAGE_SIZE)), (e, i) => i + 1).map((key, i) => (
            <PaginationDot key={key} isVisible={offset === PAGE_SIZE * i} />
          ))}
      </PaginationRow>
      {isLoading && (
        <SpinnerContainer>
          <RotateSpinner size={30} color="var(--color-green)" loading />
        </SpinnerContainer>
      )}
    </Card>
  );
};
