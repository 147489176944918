import Oidc from 'oidc-client';
import * as logger from 'src/services/log';

export interface ConfigInterface {
  authority: string;
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  post_logout_redirect_uri: string;
  automaticSilentRenew: boolean;
  silent_redirect_uri: string;
}

class User {
  config: ConfigInterface | undefined;
  mgr: Oidc.UserManager | undefined;

  getUser = async (): Promise<Oidc.User | null> => {
    if (this.config === undefined || this.mgr === undefined) {
      this.config = await this.getConfig();
      this.mgr = new Oidc.UserManager(this.config);
    }
    return await this.mgr.getUser();
  };
  removeUser = async (): Promise<void> => {
    if (this.config === undefined || this.mgr === undefined) {
      this.config = await this.getConfig();
      this.mgr = new Oidc.UserManager(this.config);
    }
    await this.mgr.removeUser();
  };

  login = async (): Promise<void> => {
    if (this.config === undefined || this.mgr === undefined) {
      this.config = await this.getConfig();
      this.mgr = new Oidc.UserManager(this.config);
    }
    this.mgr.signinRedirect();
  };

  logout = async (): Promise<void> => {
    if (this.config === undefined || this.mgr === undefined) {
      this.config = await this.getConfig();
      this.mgr = new Oidc.UserManager(this.config);
    }
    this.mgr.signoutRedirect();
  };

  getConfig = async (): Promise<ConfigInterface> => {
    const res = await fetch('/api/user-config');
    if (res.status === 200) {
      const data = await res.json();
      return data;
    } else {
      logger.error(`Failed to fetch user config with an status code of ${res.status}`, {
        stackTrace: res.statusText,
        view: 'User Class',
        action: 'User.getConfig',
      });
      throw new Error('Config not available');
    }
  };
}

export default new User();
